import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index.js"
import Login from "../views/public/Login.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "Masuk",
      all: false, 
      public: true,
      onlyLoggedOut: true,
    }
  },
  {
    path: "/",
    name: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/private/Commission.vue"),
    meta: {
      title: "Dashboard",
      all: false, 
      public: false,
    }
  },
  {
    path: "/promo",
    name: "promo",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/private/Promo.vue"),
    meta: {
      title: "Promo",
      all: false, 
      public: false,
    }
  },
  {
    path: "/promo/detail-code/:id",
    name: "Detail Kode Promo",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/private/PromoDetailCode.vue"),
    meta: {
      title: "Detail Kode Promo",
      all: false, 
      public: false,
    }
  },
  // {
  //   path: "/commission",
  //   name: "komisi",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/private/Commission.vue"),
  //   meta: {
  //     title: "Komisi",
  //     all: false, 
  //     public: false,
  //   }
  // },
  {
    path: "/withdraw",
    name: "withdraw",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/private/Withdraw.vue"),
    meta: {
      title: "Withdraw",
      all: false, 
      public: false,
    }
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const authenticated = store.state.authenticated
  const isAll = to.matched.some((record) => record.meta.all)
  const onlyLoggedOut = to.matched.some((record) => record.meta.onlyLoggedOut)
  const isPublic = to.matched.some((record) => record.meta.public)
  
  
  if (!isAll && !isPublic && !authenticated) {
    return next({
      path: "/login"
    })
  }
  if (authenticated && onlyLoggedOut) {
    return next("/")
  }
  document.title = `SODA Sales Internal | ${to.meta.title}`
  next()
})

export default router;
