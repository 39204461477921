import Vue from "vue"
import { required, email, max, min, min_value, max_value, confirmed } from "vee-validate/dist/rules"
import { extend, ValidationObserver, ValidationProvider } from "vee-validate"

extend("required", {
  ...required,
  message: "Kolom tidak boleh kosong"
});

extend("max", {
  ...max,
  message: "Kolom maksimal {length} karakter"
});

extend("min", {
  ...min,
  message: "Kolom minimal {length} karakter"
});

extend("min_10000", {
  ...min_value,
  message: "Kolom minimal 10000"
});

extend("min_0", {
  ...min_value,
  message: "Kolom minimal 0"
});

extend("min_1", {
  ...min_value,
  message: "Kolom minimal 1"
});

extend("max_100", {
  ...max_value,
  message: "Kolom maksimal 100"
});

extend('custom_min', {
  validate(value,{min}){
    return value >= min
  },
  params: ['min'],
  message: '{_field_} mininal {min}'
})

extend('custom_max', {
  validate(value,{max}){
    return value <= max
  },
  params: ['max'],
  message: '{_field_} maksimal {max}'
})

extend('min_price', {
  ...min_value,
  message: '{_field_} minimal Rp 100.000' 
})

extend('max_price', {
  ...max_value,
  message: '{_field_} maksimal Rp 5.000.000'
})


extend("email", {
  ...email,
  message: "Kolom email belum benar"
})

extend("confirmed", {
  ...confirmed,
  message: "Kolom konfirmasi password belum benar"
})

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

export default {
  components: {
    ValidationObserver,
    ValidationProvider
  }
}