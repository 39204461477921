<template>
  <v-app>
    <Navbar
      v-if="
        $route.name !== 'login' &&
          $route.name !== 'Lupa Password' &&
          $route.name !== '404'
      "
    />
    <!-- :style="$route.name === 'dashboard' ? 'padding: 0px 0px 0px 90px;' : ''" -->
    <v-main min-height="100vh" light>
      <!-- <Snackbar ref="snackbar"/> -->

      <v-snackbar
        v-model="dialog.upgrade"
        bottom
        right
        vertical
        timeout="-1"
      >
        <p class="mt-5 font-weight-bold text-center">
          Versi baru telah tersedia.
        </p>
        <p class="mt-2 text-center">
          Silahkan klik tombol Refresh untuk mendapatkan versi terbaru.
        </p>
        <template v-slot:action="{ attrs }">
          <v-btn
            text
            color="#fff"
            v-bind="attrs"
            @click.stop="dialog.upgrade = false"
          >
            Nanti
          </v-btn>
          <v-btn
            text
            color="#00f500"
            v-bind="attrs"
            @click.stop="refreshApp"
          >
            Refresh App
          </v-btn>
        </template>
      </v-snackbar>
      <router-view />
    </v-main>
    <!-- <Footer
      v-if="
        $vuetify.breakpoint.name !== 'xs' &&
          $vuetify.breakpoint.name !== 'sm' &&
          $vuetify.breakpoint.name !== 'md' &&
          $route.name !== 'login' &&
          $route.name !== 'Lupa Password' &&
          $route.name !== 'Auto Login' &&
          $route.name !== '404'
      "
    /> -->
    <!-- <v-fab-transition>
      <v-btn
        v-show="fab"
        elevation="3"
        fab
        dark
        fixed
        bottom
        right
        color="blue"
        @click="sendMessage('Hello Bos')">
        <v-icon>mdi-message</v-icon>
      </v-btn>
    </v-fab-transition> -->
  </v-app>
</template>

<script>
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
// import Snackbar from '@/components/Snackbar.vue'
export default {
  data() {
    return {
      fab: true,
      connection: null,
      dialog: {
        upgrade: false,
      },
      refreshing: false,
      registrasi: null,
      updateExists: false,
    };
  },
  components: {
    Footer,
    Navbar,
    // Snackbar,
  },
  created() {

    // SERVICE WORKER LISTENER
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      });
    }
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  watch: {},
  mounted() {},
  methods: {
    showRefreshUI(e) {
      this.registration = e.detail;
      this.updateExists = true;
      this.dialog.upgrade = true;
    },
    refreshApp() {
      this.updateExists = false;
      this.dialog.upgrade = false;
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage("skipWaiting");
    },
  },
};
</script>

<style>
#price {
  text-align: right;
}
#inputQty {
  text-align: center !important;
}
#halaman {
  text-align: center;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}

#content {
  padding: 0px;
}
</style>
