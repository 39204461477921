import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    errorNetwork: false,
    is_trial: false,
    loading: false,
		unauthorization: false,
    token: "",
    serial: "",
    authenticated: false,
    user: {},
  },
  mutations: {
    SET(state, {
      key,
      value
    }) {
      state[key] = value;
    },
    setToken(state, accessToken) {
      state.token = accessToken
      state.authenticated = true
    },
    setSerial(state, accessSerial) {
      state.serial = accessSerial
    },
    setUser(state, accessUser) {
      state.user = accessUser
    }
  },
  actions: {
  },
  modules: {
  }
})
