<template>
  <div class="background-default" style="min-height: 100vh;">
    <unAuthorized />
    <errorNetwork />
    <v-container>
      <!-- ==== FORM LOGIN ==== -->
      <v-row align="center">
        <v-col
          cols="12"
          md="4"
          :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'pa-5 justify-center' : 'pa-0'"
          class="d-flex align-center"
          style="min-height: 97vh;">
          <v-card :width="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 500 : '100%'" class="box-shadow border-radius">
            <v-row align="center">
              <v-col cols="12" class="pa-8 text-center">
                <img src="@/assets/logo/logo-depan.png" class="mb-5" width="200" />
                <div class="d-flex align-center mb-5">
                  <v-divider color="white"></v-divider>
                  <div class="subtitle-2 mx-2 font-weight-bold color-default">
                    Masuk Internal Sales SODA POS
                  </div>
                  <v-divider color="white"></v-divider>
                </div>
                <ValidationObserver ref="observer">
                  <v-form class="text-left mb-5">
                    <ValidationProvider
                      name="Email"
                      rules="required|max:50|email"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        v-on:keyup.enter="save"
                        outlined
                        dense
                        single-line
                        color="#d31145"
                        placeholder="Email"
                        v-model="form.email"
                        prepend-inner-icon="mdi-account"
                        :error-messages="errors"
                        required
                      >
                      </v-text-field>
                    </ValidationProvider>
                    <ValidationProvider
                      name="Password"
                      rules="required|min:6"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        v-on:keyup.enter="save"
                        class="mt-2 mb-1"
                        outlined
                        color="#d31145"
                        dense
                        single-line
                        placeholder="Password"
                        v-model="form.password"
                        :type="show ? 'text' : 'password'"
                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show = !show"
                        prepend-inner-icon="mdi-lock"
                        :error-messages="errors"
                        required
                      >
                      </v-text-field>
                    </ValidationProvider>
                  </v-form>
                </ValidationObserver>
                <div class="text-center">
                  <v-alert
                    type="error"
                    text
                    dense
                    prominent
                    v-show="error.message.length > 0"
                    v-html="error.message"
                  >
                  </v-alert>
                  <v-btn
                    block
                    elevation="3"
                    color="#d31145"
                    :disabled="process.run"
                    :loading="process.run"
                    v-on:keyup.enter="save"
                    @click="save"
                    class="white--text text-capitalize"
                  >
                    Masuk
                  </v-btn>
                  <!-- <v-btn
                    depressed
                    block
                    color="#F5F5F5"
                    class="text-capitalize blue--text text--lighten-1 mt-3"
                    to="forgot_password"
                  >
                    Lupa Password ?
                  </v-btn> -->
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" md="8" class="" v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
          <v-img
            src="@/assets/images/image_section_1.png"
            class="ml-auto"
            style="margin-right: -65px;"
            max-width="450"
          >
          </v-img>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { TokenService } from "../../service/Storage.Service";
import { post } from "@/service/Axios";
import unAuthorized from "@/components/401.vue";
import errorNetwork from "@/components/500.vue";
export default {
  components: {
    unAuthorized,
    errorNetwork,
  },
  data() {
    return {
      dialog: {
        errorNetwork: false,
      },
      show: false,
      form: {
        email: "",
        password: "",
        fcm_token: localStorage.getItem("TOKEN_FCM"),
      },
      process: {
        run: false,
      },
      error: {
        message: "",
      },
    };
  },
  created() {
  },
  methods: {
    async save() {
      this.error.message = "";
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.process.run = true;
        await post(`sales/login`, {
          data: {
            email: this.form.email,
            password: this.form.password,
            device: "web"
          },
        })
          .then((response) => {
            let res = response.data;
            if (res.status === 200) {
              TokenService.saveToken(
                res.results.token,
                JSON.stringify(res.results.data)
              );
              // localStorage.setItem("event_selected", null)
              window.location = "/"
            } else {
              this.process.run = false;
              this.error.message = res.message;
            }
          })
          .catch((error) => {
            if (error.response) {
              // let errorData = error.response.data;
              // let errorHeader = error.response.headers;
              let errorCode = error.response.status;

              if (errorCode === 401) {
                this.$store.state.unauthorization = true;
                this.process.run = false;
              }

              if (errorCode === 500) {
                this.$store.state.errorNetwork = true;
                this.process.run = false;
              }
            } else {
              this.$store.state.errorNetwork = true;
              this.process.run = false;
            }
          });
      }else {
        this.process.run = false;
      }
    },
  },
};
</script>
