<template>
  <div>
    <v-divider style="border-color: #f9f9f9;"/>
    <v-container
      v-if="$route.name !== 'faqlite' && $route.name !== 'privacylite' && $route.name !== 'aboutlite'">
      <v-row justify="center" align="center" class="pt-4 white--text">
        <v-col cols="12" sm="6">
          <img alt="sodapos" 
            width="180"
            :src="require('@/assets/logo/logo.png')"/>
        </v-col>
        <v-col cols="12" sm="6">
          <div class="d-flex justify-end">
            <a
              v-for="(item, index) in download"
              :key="index"
              :href="item.link"
              target="_blank">
              <img alt="sodapos"
                width="100"
                height="auto"
                class="mr-5"
                :src="item.icon">
            </a>
          </div>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row justify="center" class="pt-4">
        <v-col cols="12" sm="5">
          <p class="body-1 font-weight-bold mt-3 mb-0">
            Kantor Pusat
          </p>
          <div class="my-5">
            <div class="body-2 line-text-second text-second">
              Jl. Ahmad Wahid 172B Kalangan, Baturetno, Banguntapan, <br> Bantul, Yogyakarta, Indonesia
            </div>
            <div class="body-2 line-text-second text-second">
              Office: +62 822 111 77 277
            </div>
            <div class="body-2 line-text-second text-second">
              Email : info@sodapos.com
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="7">
          <v-row>
            <v-col cols="12" sm="4">
              <p class="body-1 font-weight-bold mb-5">
                Support
              </p>
              <div v-for="(item, index) in support" :key="index">
                <a :href="item.link" target="_blank" class="link">
                  <p class="body-2 text-second mb-1 hover-link-white">
                    {{ item.label }}
                  </p>
                </a>
              </div>
              <div>
                <a href="https://affiliate.sodapos.com" target="_blank">
                  <p class="body-2 text-second mb-1 hover-link-white">Gabung Affiliate</p>
                </a>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <p class="body-1 font-weight-bold mb-5">
                Tentang Kami
              </p>
              <div v-for="(item, index) in aboutus" :key="index">
                <a :href="item.link" target="_blank" class="link">
                  <p class="body-2 text-second mb-1 hover-link-white">
                    {{ item.label }}
                  </p>
                </a>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <p class="body-1 font-weight-bold mb-5">
                Sosial Media
              </p>
              <v-btn
                v-for="(item, index) in sosmed"
                :key="index"
                rounded
                class="text-capitalize white--text body-2 font-weight-bold mr-3 mb-3"
                icon
                elevation="0"
                :href="item.link" target="_blank">
                <v-img
                  width="40"
                  height="40"
                  contain
                  alt="sodapos"
                  :src="item.icon">
                </v-img>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <div class="body-2 white--text"
      style="background: #343641;">
      <v-container class="py-3">
        <div class="text-center">
          Powered by Era Solusi Data @2020
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        support: [
          // {
          //   label: 'Bantuan',
          //   link: '/help'
          // },
          {
            label: 'FAQ',
            link: 'https://sodapos.com/faq'
          },
          {
            label: 'Syarat dan Ketentuan',
            link: 'https://sodapos.com/privacy'
          }
        ],
        aboutus: [
          {
            label: 'Tentang SODA POS',
            link: 'https://sodapos.com/about'
          },
          // {
          //   label: 'Tim SODA POS',
          //   link: '/'
          // },
          // {
          //   label: 'Karir',
          //   link: '/'
          // }
        ],
        download: [
          {
            // icon: require("@/assets/logo/play_store.png"),
            link: 'https://play.google.com/store/apps/details?id=com.sodapos'
          },
          // {
          //   icon: require("@/assets/logo/app_store.png"),
          //   link: ''
          // }
        ],
        // sosmed: [
        //   {
        //     icon: require('@/assets/sosmed/ic_share_fb.svg'),
        //     link: 'https://www.facebook.com/soda.pos'
        //   },
        //   {
        //     icon: require('@/assets/sosmed/ic_share_ig.svg'),
        //     link: 'https://instagram.com/sodapos.official'
        //   },
        //   {
        //     icon: require('@/assets/sosmed/ic_share_tw.svg'),
        //     link: 'https://twitter.com/soda_pos'
        //   },
        //   {
        //     icon: require('@/assets/sosmed/ic_share_wa.svg'),
        //     link: 'https://wa.me/6282211177277'
        //   }
        // ],
        process: {
          run: false
        },
        success: {
          message: ''
        },
        error: {
          message: ''
        }
      }
    },
    methods: {
    }
  }
</script>
